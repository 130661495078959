/*
 * @Author: YYH
 * @Date: 2023-07-06 18:22:22
 * @LastEditors: YYH
 * @Description: 
 */
import React, { useState, useEffect } from "react";
import "./index.css"
import styles from "./index.module.less";
import HeadColumn from "@/components/HeadColumn";
import {
  isRunningInElectronApp
} from "@/utils";
const LoadingComponent = () => {
  const [isElectron, setIsElectron] = useState(false);
  useEffect(() => {
    if (isRunningInElectronApp()) {
      setIsElectron(true)
      console.log('当前在 Electron 应用中打开');
    } else {
      setIsElectron(false)
      console.log('当前在浏览器中打开');
    }
  }, []);
  return (
    <div className={`${styles.loadingComponent} ${isElectron ? styles.loadingComponentBorder : ''}`}>
      <HeadColumn></HeadColumn>
      <div className="loading-wave">
        <div className="loading-bar"></div>
        <div className="loading-bar"></div>
        <div className="loading-bar"></div>
        <div className="loading-bar"></div>
      </div>
      {/* <div className="square-gradient">
        <i className="gradient"></i>
        <i className="gradient"></i>
        <i className="gradient"></i>

        <i className="gradient"></i>
        <i className="gradient"></i>
        <i className="gradient"></i>

        <i className="gradient"></i>
        <i className="gradient"></i>
        <i className="gradient"></i>
      </div> */}
    </div>
  );
};

export default LoadingComponent;
