/*
 * @Description: 
 * @Version: 2.0
 * @Autor: jl.g
 * @Date: 2023-06-29 11:10:03
 * @LastEditors: Chenck
 * @LastEditTime: 2024-07-02 15:36:39
 */
import navicon1 from "../../public/assets/imgs/navicon1.svg";
import navicon2 from "../../public/assets/imgs/navicon2.svg";
import navicon3 from "../../public/assets/imgs/navicon3.svg";
import navicon4 from "../../public/assets/imgs/navicon4.svg";
import navicon5 from "../../public/assets/imgs/navicon5.svg";
import navicon6 from "../../public/assets/imgs/navicon6.svg";
import navicon7 from "../../public/assets/imgs/navicon7.svg";
import navicon8 from "../../public/assets/imgs/navicon8.svg";
import navicon9 from "../../public/assets/imgs/navicon9.svg";
import navicon10 from "../../public/assets/imgs/navicon10.svg";
import knowsBase from "../../public/assets/imgs/knows-base.svg"
import navicon11 from "../../public/assets/imgs/contact-us.svg"
import navicon12 from "../../public/assets/imgs/navicon12.svg"
import navicon13 from "../../public/assets/imgs/navicon13.svg"
import navicon14 from "../../public/assets/imgs/navicon14.svg"
import navicon15 from "../../public/assets/imgs/navicon15.svg"
import navicon16 from "../../public/assets/imgs/navicon16.svg"
import navicon17 from "../../public/assets/imgs/navicon17.svg"
import navicon18 from "../../public/assets/imgs/navicon18.svg"
import navicon19 from "../../public/assets/imgs/navicon19.svg"

export const menuDictionary = [{
    id: 1,
    label: "对话",
    path: "/ai/home",
    icon: navicon1
},
{
    id: 107,
    label: "AI绘画",
    path: "/ai/aiDraw",
    icon: navicon2
},
{
    id: 3,
    label: "AI导航",
    path: "/ai/aiNavigation",
    icon: navicon3
},
{
    id: 4,
    label: "应用广场",
    path: "/ai/aiAssistant",
    icon: navicon4
},
{
    id: 5,
    label: "知识库",
    path: "/ai/knowledge",
    // path: "/doc/knowledge",
    icon: knowsBase
},
{
    id: 6,
    label: "思维导图",
    path: "/ai/mindMap",
    icon: navicon10
},
// {
//     id: 100,
//     label: "收藏",
//     path: "/ai/aiNavigation",
//     icon: navicon3
// },
// {
//     id: 102,
//     label: "商机人脉",
//     path: "/ai/aiAssistant",
//     icon: navicon5
// },
{
    id: 104,
    label: "企业分析",
    path: "/ai/busReport",
    icon: navicon9
},
{
    id: 106,
    label: "研报中心",
    path: "/ai/aiReport",
    icon: navicon12
},
{
    id: 105,
    label: "AI·PPT",
    path: "/ai/aiPpt",
    icon: navicon13
},
{
    id: 108,
    label: "产业圈子",
    path: "/ai/industrialCircle",
    icon: navicon6
},
{
    id: 303,
    label: "商机对接",
    path: "/ai/businessDocking",
    icon: navicon18
},
{
    id: 304,
    label: "活动专区",
    path: "/ai/activityArea",
    icon: navicon19
},
{
    id: 109,
    label: "产业舆情早报",
    path: "/ai/busOpportunity",
    icon: navicon14
},
{
    id: 1000,
    label: "账户及套餐",
    path: "/ai/account",
    icon: navicon7
},
{
    id: 1001,
    label: "设置",
    path: "/ai/setting",
    icon: navicon8
},
{
    id: 1002,
    label: "联系我们",
    path: "/ai/contactUs",
    icon: navicon11
},
{
    id: 110,
    label: "知识库",
    path: "/ai/aiReadingDocument",
    icon: knowsBase,
},
{
    id: 300,
    label: "云上投洽会",
    path: "/ai/cloudInvestment",
    icon: navicon15,
},
{
    id: 301,
    label: "智慧招商",
    path: "/ai/investmentPromotion",
    icon: navicon16,
},
{
    id: 302,
    label: "联网智搜",
    path: "/ai/internetSearch",
    icon: navicon17
},
]
