/*
 * @Description:
 * @Version: 2.0
 * @Autor: jl.g
 * @Date: 2022-08-17 13:31:40
 * @LastEditors: YYH
 * @LastEditTime: 2024-07-31 16:30:09
 */
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import LoadingComponent from "@/components/LoadingComponent"
import H5Component from "@/components/H5Component"
import "./theme/light.css";
import "./theme/dark.css";
import "./index.css";

const App = lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));
const whiteList = ['/ai/aiLevitatedSphere', '/ai/aiHoverBall', '/ai/aiUpdates'];
root.render(
  <HashRouter>
    {window.location.href.includes('/h5/') ?
      <Suspense fallback={<H5Component></H5Component>}>
        <App />
      </Suspense>
      : !whiteList.includes(window.location.href.split('#')[1]) ?
        <Suspense fallback={<LoadingComponent></LoadingComponent>}>
          <App />
        </Suspense>
        :
        <App />
    }
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
